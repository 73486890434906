import {
  getValidationError,
  inputTypesEnum,
  validationTypesEnum
} from "@/validator/errorMessages";
import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";
import { ValidatorResponse } from "@/validator/ValidatorResponse";
const { password } = inputTypesEnum;
const { equal } = validationTypesEnum;

export function equalTo(
  value: string,
  toEqualValue?: string
): IValidatorResponse {
  const valid: boolean = value === toEqualValue;
  return new ValidatorResponse(
    valid,
    valid ? "" : getValidationError(password, equal)
  );
}
