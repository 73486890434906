import {
  getValidationError,
  inputTypesEnum,
  validationTypesEnum
} from "@/validator/errorMessages";
import { isNotEmptyString } from "@/validator/utilles/isNotEmptyString";
import { ValidatorResponse } from "@/validator/ValidatorResponse";
import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";

const { name: nameType } = inputTypesEnum;
const { required, minLength, base } = validationTypesEnum;

export function name(value: string): IValidatorResponse {
  let errorMessage = "";
  let status = false;

  if (!isNotEmptyString(value)) {
    errorMessage = getValidationError(nameType, required);
  } else if (value.trim().length < 2) {
    errorMessage = getValidationError(nameType, minLength);
  } else {
    status = true;
  }

  return new ValidatorResponse(status, errorMessage);
}
