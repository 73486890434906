export enum validationTypesEnum {
  required = "required",
  minLength = "minLength",
  equal = "equal",
  base = "base",
  price = "price",
  number = "number"
}

export enum inputTypesEnum {
  email = "email",
  password = "password",
  name = "name",
  unknown = "unknown",
  phone = "phone",
  price = "price",
  number = "number"
}

const { email, password, name, unknown, phone, price } = inputTypesEnum;
const { required, minLength, equal, base, number } = validationTypesEnum;

type IValidationError = {
  [key: string]: string | undefined;
};

export interface ValidatorInputType {
  [key: string]: IValidationError | undefined;
}

export const inputError: IValidationError = {
  empty: "This field cannot be empty",
  base: "The field is filled incorrectly",
  nameIsEmpty: "Name cannot be empty",
  nameIsTooShort: "Field must have at least 3 symbols",
  price: "Price must be greater than 0",
  number: "Only numbers are supported"
};

export const validationErrors: ValidatorInputType = {
  [email]: {
    [required]: "Email cannot be empty",
    [base]: "Email is not valid"
  },

  [price]: {
    [base]: inputError.price,
    [number]: "Amount must be a number"
  },

  [password]: {
    [required]: "Password cannot be empty",
    [minLength]: "Password must be at least 8 symbols",
    [equal]: "Passwords do not match",
    [base]: "Password must have at least 1 uppercase symbol and 1 number"
  },

  [name]: {
    [required]: "Name cannot be empty",
    [minLength]: "Field must have at least 3 symbols",
    [base]: "Name is incorrect"
  },

  [unknown]: {
    [required]: "Field cannot be empty",
    [minLength]: "Field is too short",
    [base]: "The field is filled incorrectly"
  },

  [phone]: {
    [required]: "Phone cannot be empty",
    [base]: "Incorrect phone"
  }
};

export function getValidationError(
  inputType: string,
  errorType: string
): string {
  const type: IValidationError | undefined =
    validationErrors[inputType] ?? validationErrors[unknown];

  if (type) {
    return type[errorType] || type[base] || "";
  }

  return "";
}

export function getInputError(type: string) {
  if (inputError[type]) {
    return inputError[type];
  }

  return inputError.base;
}
