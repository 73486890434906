import {
  getValidationError,
  inputTypesEnum,
  validationTypesEnum
} from "@/validator/errorMessages";
import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";
import { ValidatorResponse } from "@/validator/ValidatorResponse";

// const phoneRegex = new RegExp(
//   /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-. \\/]?)?((?:\(?\d{1,}\)?[-. \\/]?){0,})(?:[-. \\/]?(?:#|ext\.?|extension|x)[-. \\/]?(\d+))?$/gim
// );

const phoneRegex = /^\+[0-9\-().\s]{7,15}$/;
const { phone: phoneType } = inputTypesEnum;
const { base } = validationTypesEnum;

export function phone(value: string): IValidatorResponse {
  const isValid = phoneRegex.test(value) && value.trim().length > 6;

  return new ValidatorResponse(
    isValid,
    isValid ? "" : getValidationError(phoneType, base)
  );
}
