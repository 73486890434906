import { getValidationError, validationTypesEnum } from "../errorMessages";
import { IValidatorResponse } from "../interfaces/IValidatorResponse";
import { ValidatorResponse } from "../ValidatorResponse";

export function required(value: string): IValidatorResponse {
  const status = !!value.trim().length;

  return new ValidatorResponse(
    status,
    getValidationError("**", validationTypesEnum.required)
  );
}
