import {
  getValidationError,
  inputTypesEnum,
  validationTypesEnum
} from "@/validator/errorMessages";
import { isNotEmptyString } from "@/validator/utilles/isNotEmptyString";
import { ValidatorResponse } from "@/validator/ValidatorResponse";

const regexp = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\W]{8,}$/);
const { password: passwordType } = inputTypesEnum;
const { required, minLength, base } = validationTypesEnum;

export function password(value: string) {
  let status = false;
  let message = "";

  if (!isNotEmptyString(value)) {
    message = getValidationError(passwordType, required);
  } else if (value.trim().length < 8) {
    message = getValidationError(passwordType, minLength);
  } else if (!regexp.test(value)) {
    message = getValidationError(passwordType, base);
  } else {
    status = true;
  }

  return new ValidatorResponse(status, message);
}
